<!-- src/components/Login.vue -->
<template>
  <div class="container">
    <h1>Login</h1>

    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>

    <input v-model="username" type="text" placeholder="E-Mail" /><br />
    <input v-model="password" type="password" placeholder="Passwort" /><br />
    <button @click="onLogin">Einloggen</button>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { login, useUser } from '@/utils/login'
import { useRouter } from 'vue-router'

export default {
  name: 'Login',
  setup () {
    const username = ref('')
    const password = ref('')
    const errorMessage = ref('')
    const router = useRouter()
    const user = useUser()

    async function onLogin () {
      if (username.value && password.value) {
        try {
          const response = await login(username.value, password.value)
          if (response) {
            router.push('/')
          } else {
            errorMessage.value = 'Benutzername oder Passwort falsch'
          }
        } catch (error: any) {
          if (error.response?.data?.message) {
            errorMessage.value = error.response.data.message
          } else {
            errorMessage.value = 'Ein Fehler ist aufgetreten'
          }
        }
      }
    }

    return {
      username,
      password,
      errorMessage,
      onLogin
    }
  }
}
</script>

<style scoped>
.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ef9a9a;
}

input {
  margin: 8px 0;
  padding: 8px;
  width: 100%;
  max-width: 300px;
}

button {
  margin-top: 8px;
  padding: 8px 16px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #3aa876;
}
</style>
