<!-- src/components/ProductFinder.vue -->
<template>
  <div class="finder">
    <input
      type="text"
      v-model="searchText"
      @keyup.enter="search"
      @input="onInput"
      ref="searchField"
      placeholder="Barcode oder Produktname eingeben"
    >
    <div v-if="searchResults.length > 0" class="search-results">
      <div
        v-for="product in searchResults"
        :key="product.id"
        class="search-result-item"
        @click="selectProduct(product)"
      >
        <img :src="apiHost + product.image" :alt="product.name" class="product-image">
        <div class="product-info">
          <div class="product-name">{{ product.name }}</div>
          <div class="product-variant">{{ product.variant }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import axios from 'axios'
import { Product } from '@/types'

// Debounce-Funktion mit TypeScript-Typen
type AnyFunction = (...args: unknown[]) => unknown

function debounce<F extends AnyFunction> (func: F, wait: number): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const later = () => {
      timeout = null
      func.apply(this, args)
    }
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default defineComponent({
  name: 'ProductFinder',
  emits: ['select'],
  setup (props, { emit }) {
    const searchText = ref('')
    const searchField = ref<HTMLElement | null>(null)
    const searchResults = ref<Product[]>([])
    const apiHost = computed(() => {
      return process.env.VUE_APP_HOST
    })
    async function search () {
      const barcode = parseInt(searchText.value)

      if (!isNaN(barcode)) {
        await searchByBarcode(barcode)
      } else {
        await searchByText(searchText.value)
      }
    }

    async function searchByBarcode (barcode: number) {
      // console.log(barcode)
      const response = await axios.get(`/bybarcode?barcode=${barcode}`)

      // console.log(response.data)
      searchText.value = ''
      if (response.data) {
        selectProduct(response.data as Product)
      } else {
        alert('Produkt nicht gefunden!')
      }
    }

    async function searchByText (searchText: string) {
      const response = await axios.get('/bytext', {
        params: { text: searchText }
      })

      // console.log(response.data)
      if (response.data && response.data.length > 0) {
        searchResults.value = response.data
      } else {
        searchResults.value = []
        alert('Keine Produkte gefunden!')
      }
    }

    function selectProduct (product: Product) {
      emit('select', product)
      searchText.value = ''
      searchResults.value = []
      if (searchField.value) {
        searchField.value.focus()
      }
    }

    const onInput = debounce(() => {
      if (searchText.value.length > 2) {
        search()
      } else {
        searchResults.value = []
      }
    }, 300)

    return {
      searchText,
      search,
      searchField,
      searchResults,
      selectProduct,
      onInput,
      apiHost
    }
  }
})
</script>

<style scoped lang="scss">
.finder {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

input {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: -81px;
  right: 0;
  background: white;
  max-height: 1030px;
  overflow-y: auto;
  z-index: 1000;
  display: grid;
  width: 120%;
  grid-template-columns: repeat(3, 1fr);
}

.search-result-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: #eee;
  border: 1px solid #aaa;
  margin: 5px;
  padding: 5px;
  &:hover {
    background-color: #f0f0f0;
  }
}

.product-image {
  width: 100%;
  /*height: 50px;*/
  object-fit: cover;
}

.product-info {
  flex-grow: 1;
}

.product-name {
  font-weight: bold;
}

.product-variant {
  font-size: 0.9em;
  color: #666;
}
</style>
