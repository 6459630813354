// src/utils/auth.ts
import { useToken } from './login'
import { Router } from 'vue-router'

export function setupAuthGuard (router: Router):void {
  router.beforeEach((to, from, next) => {
    const token = useToken()
    const isAuthenticated = !!token.value

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  })
}
