
import { defineComponent, watch } from 'vue'
import { logout, useToken, useUser } from '@/utils/login'
import Login from '@/components/Login.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: { Login },
  setup () {
    const token = useToken()
    const user = useUser()
    const router = useRouter()

    function onLogout () {
      logout()
      router.push('/login')
    }

    watch(token, (newToken) => {
      if (!newToken) {
        router.push('/login')
      }
    })

    return {
      token,
      user,
      onLogout
    }
  }
})
