
import { ref } from 'vue'
import { login, useUser } from '@/utils/login'
import { useRouter } from 'vue-router'

export default {
  name: 'Login',
  setup () {
    const username = ref('')
    const password = ref('')
    const errorMessage = ref('')
    const router = useRouter()
    const user = useUser()

    async function onLogin () {
      if (username.value && password.value) {
        try {
          const response = await login(username.value, password.value)
          if (response) {
            router.push('/')
          } else {
            errorMessage.value = 'Benutzername oder Passwort falsch'
          }
        } catch (error: any) {
          if (error.response?.data?.message) {
            errorMessage.value = error.response.data.message
          } else {
            errorMessage.value = 'Ein Fehler ist aufgetreten'
          }
        }
      }
    }

    return {
      username,
      password,
      errorMessage,
      onLogin
    }
  }
}
