// src/utils/axios.ts
import axios from 'axios'
import { logout, useToken } from '@/utils/login'

const token = useToken()

axios.interceptors.request.use(function (config) {
  const jwt = token.value
  config.headers.Authorization = jwt

  // console.log('set token', jwt)

  return config
})
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    alert('Login abgeloffen, bitte erneut einloggen.')
    logout()
  } else {
    return Promise.reject(error)
  }
})
