
import { computed, defineComponent, PropType, ref, ComputedRef } from 'vue'
import { CartEntry } from '@/types'
import { formatCurrency } from '@/utils/currency'
import axios from 'axios'

export default defineComponent({
  name: 'Order',
  props: {
    products: {
      type: Object as PropType<CartEntry[]>,
      required: true
    }
  },
  setup (props) {
    const discount = ref<number>(0)
    const additionalText = ref<string>('')
    const additionalPrice = ref<number>(0)

    const bruttoPrice = computed<number>(() => {
      let price = 0
      props.products.forEach(p => {
        price += (p.amount * p.product.price)
      })
      return price + additionalPrice.value
    })

    const discountAmount = computed<number>(() => {
      return Math.round(bruttoPrice.value * (discount.value / 100) * 20) / 20
    })

    const nettoPrice: ComputedRef<number> = computed<number>(() => {
      return bruttoPrice.value - discountAmount.value
    })

    const apiHost = computed(() => process.env.VUE_APP_HOST)

    async function order () {
      try {
        const response = await axios.post('/order', {
          products: props.products,
          additionalText: additionalText.value,
          additionalPrice: additionalPrice.value,
          netto: nettoPrice.value,
          discount: discountAmount.value,
          brutto: bruttoPrice.value
        })

        if (response.data.success && response.data.id) {
          window.open(`${apiHost.value}/deliverynote/${response.data.id}`)
        } else {
          console.error('Failed to create order:', response.data)
          alert('Fehler beim Erstellen der Bestellung: ' + (response.data.message || JSON.stringify(response.data)))
        }
      } catch (error: any) {
        console.error('Error creating order:', error)
        if (error.response) {
          alert('Fehler beim Erstellen der Bestellung: ' + (error.response.data.message || JSON.stringify(error.response.data)))
        } else if (error.request) {
          alert('Keine Antwort vom Server erhalten. Bitte überprüfen Sie Ihre Internetverbindung.')
        } else {
          alert('Fehler beim Senden der Anfrage: ' + error.message)
        }
      }
    }

    return {
      bruttoPrice,
      formatCurrency,
      discount,
      discountAmount,
      nettoPrice,
      additionalPrice,
      additionalText,
      order
    }
  }
})
