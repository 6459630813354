<!-- src/views/Pos.vue -->
<template>
  <div class="container">
    <h1>Neue Bestellung erfassen</h1>

    <h3>Produkte hinzufügen</h3>
    <ProductFinder @select="addProduct" />

    <ProductList :products="currentProducts" @remove="remove" />

    <Order :products="currentProducts" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ProductFinder from '@/components/ProductFinder.vue'
import { Product } from '@/types'
import ProductList from '@/components/ProductList.vue'
import Order from '@/components/Order.vue'
interface CartEntry {
  product: Product;
  amount: number;
}
export default defineComponent({
  name: 'POS',
  components: {
    ProductFinder,
    ProductList,
    Order
  },
  setup () {
    const currentProducts = ref<CartEntry[]>([])

    function remove (productId: number) {
      console.log('remove', productId)
      currentProducts.value = currentProducts.value.filter(p => p.product.id !== productId)
    }

    function addProduct (product: Product) {
      let alreadyAdded = false

      currentProducts.value.forEach(p => {
        if (p.product.id === product.id) {
          p.amount++
          alreadyAdded = true
        }
      })

      if (!alreadyAdded) {
        currentProducts.value.push({
          product: product,
          amount: 1
        })
      }
    }

    return {
      addProduct,
      currentProducts,
      remove
    }
  }
})
</script>

<style lang="scss">

</style>
